<template>

  <div class="loginPage_wrapper pochta">
    <div class="header">
      <div class="logo"></div>
      <div class="logo_accent_black"></div>
    </div>

    <div class="main">
      <div class="title">АИС Управления инвестициями</div>
      <div class="logo_form">
        <slot name="loginForm"></slot>
      </div>
    </div>

    <div class="faq">
      <span class="icon"></span>
      <a href="https://support.russianpost.ru/portal/navigator-service-call.html?service=service$2421508201"
          target="_blank">Обратиться в поддержку</a>
     </div>

  </div>

</template>

<script>
export default {
  name: 'login-pochta'
}
</script>

<style lang="scss">

.loginPage_title {
  display: none;
}
.pochta {

  &.loginPage_wrapper{
    display: block;
    padding: 40px;
    padding-left: 70px;
    box-sizing: border-box;
    background: url(./resources/partial-repair.jpg) no-repeat;
    background-position: right;
    background-size: contain;
    display: flex;
    flex-direction: column;
  }

  .header {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    background: url(./resources/logo_pochta.svg) no-repeat;
    width: 141px;
    height: 68px;
  }

  .logo_accent_black {
    background: url(./resources/accent_logo_black.svg) no-repeat;
    width: 264px;
    height: 52px;
    background-size: 200px;
  }

  .title {
    font-size: 56px;
    line-height: 60px;
    color: #141A1F;
    margin-bottom: 20px;
  }

  .logo_form {
    width: 380px;
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
  }
  .faq {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 290px;
    margin-top: auto;
  }
  .faq a{
    font-size: 21px;
    color: #788591;
  }
  .icon {
    background: url(./resources/faq.svg) no-repeat;
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 860px) {
    .pochta .title {
      font-size: 32px;
      line-height: 32px;
    }
}
</style>
